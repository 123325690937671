import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Spinner } from 'react-bootstrap';

const PatientEmailConfirmation = (props) => {
    const [email, setEmail] = useState("");
    const [PDFemail, setPDFEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPDFEmailValid, setIsPDFEmailValid] = useState(false);
    const [showEmailField, setShowEmailField] = useState(false);
    const [isEmailAvailable, setIsEmailAvailable] = useState(false);
    const [isStartButtonEnabled, setIsStartButtonEnabled] = useState(false);
    const [isFollowStartButtonEnabled, setIsFollowStartButtonEnabled] = useState(false);
    const [InterpreterData, setInterpreterData] = useState({ OtherLang: "", Lang: "", ID: "", InterpreterNeeded: true, InterpreterType: "", Name: "", Representative: "", Reason: "", InterpreterEmail: "", InterpreterEmailRequired: false, isInterpreterEmailRequired: "" });
    const [languagesList, setLanguagesList] = useState(props.cclLang)
    const [filteredLanguages, setFilteredLanguages] = useState(languagesList);
    const [showccLLang, setShowCclLang] = useState(false)
    const [inputValue, setInputValue] = useState("");
    const [sendFollowUpEmailData, setSendFollowUpEmailData] = useState({ dischargeDate: "", diagnosis: "", patientEmail: "", providerEmail: "", providerName: "" });
    const handleSearchItemChange = (e) => {
        const { id, value } = e.target;
        let updatedData = { ...sendFollowUpEmailData };
        updatedData = {
            ...sendFollowUpEmailData,
            [id]: value
        }
        setSendFollowUpEmailData(updatedData);
        checkIsFollowUpStartButtonEnabled(updatedData);
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    useEffect(() => {
        if (props.patientData.email !== null && props.patientData.email !== "" && isValidEmail(props.patientData.email)) {
            setEmail(props.patientData.email);
            setIsEmailAvailable(true);
        }
        else if (props.patientMetadata.email !== null && props.patientMetadata.email !== "") {
            setEmail(props.patientMetadata.email)
            setIsEmailAvailable(true);
        }
    }, [])
    useEffect(() => {
        var checkEmail = isValidEmail(email);
        setIsEmailValid(checkEmail)
    }, [email]);
    useEffect(() => {
        var checkEmail = isValidEmail(PDFemail);
        setIsPDFEmailValid(checkEmail)
    }, [PDFemail]);
    const inputHandler = (e) => {
        setPDFEmail(e.target.value);
    }
    const onSaveEmail = () => {
        setEmail(PDFemail);
        setPDFEmail("");
        setIsEmailAvailable(true);
        setShowEmailField(false);
    }
    const handleInterpreterChange = (e) => {
        const { id, value, checked } = e.target;
        let updatedData = { ...InterpreterData };
        if (id === 'InterpreterNeeded') {
            const isInterpreterNeeded = !InterpreterData.InterpreterNeeded;
            updatedData = {
                ...InterpreterData,
                InterpreterNeeded: isInterpreterNeeded,
                Lang: isInterpreterNeeded ? InterpreterData.Lang : '',
                ID: isInterpreterNeeded ? InterpreterData.ID : '',
                OtherLang: isInterpreterNeeded ? InterpreterData.OtherLang : "",
                InterpreterType: "",
                Representative: isInterpreterNeeded ? InterpreterData.Representative : "",
                Name: isInterpreterNeeded ? InterpreterData.Name : "",
                Reason: !isInterpreterNeeded ? InterpreterData.Reason : "",
                InterpreterEmail: isInterpreterNeeded ? InterpreterData.InterpreterEmail : "",
                InterpreterEmailRequired: value === isInterpreterNeeded ? InterpreterData.InterpreterEmailRequired : false,
                isInterpreterEmailRequired: isInterpreterNeeded ? InterpreterData.isInterpreterEmailRequired : ""
            };
        }
        else if (id === 'isInterpreterEmailRequired') {
            updatedData = {
                ...InterpreterData,
                isInterpreterEmailRequired: value,
                InterpreterEmailRequired: value === "Yes" ? true : false,
                InterpreterEmail: !InterpreterData.InterpreterEmailRequired ? InterpreterData.InterpreterEmail : ""
            };
        }
        else {

            updatedData = {
                ...InterpreterData,
                [id]: value,
            };
            if (id === "Lang") {
                const filtered = languagesList.filter((language) =>
                    language.toLowerCase().startsWith(value.toLowerCase())
                );
                setFilteredLanguages(filtered);
                setInputValue(value);
                setShowCclLang(true)
            }
        }

        setInterpreterData(updatedData);
        checkIsStartButtonEnabled(updatedData);
    };

    const checkIsStartButtonEnabled = (data) => {
        const { InterpreterNeeded, ID, Lang, InterpreterType, Name, Representative, Reason, InterpreterEmail, InterpreterEmailRequired, isInterpreterEmailRequired } = data;
        if (InterpreterNeeded && ID !== '' && Lang !== '' && Lang != "Other" && InterpreterType != '' && Name != "" && Representative != "" && InterpreterEmailRequired && isInterpreterEmailRequired === "Yes" && isValidEmail(InterpreterEmail)) {
            setIsStartButtonEnabled(true);
        }
        else if (InterpreterNeeded && ID !== '' && Lang == 'Other' && InterpreterData.OtherLang != "" && InterpreterType != '' && Name != "" && Representative != "" && InterpreterEmailRequired && isInterpreterEmailRequired === "Yes" && isValidEmail(InterpreterEmail)) {
            setIsStartButtonEnabled(true);
        }
        else if (InterpreterNeeded && ID !== '' && Lang !== '' && Lang != "Other" && InterpreterType != '' && Name != "" && Representative != "" && !InterpreterEmailRequired && isInterpreterEmailRequired == "No") {
            setIsStartButtonEnabled(true);
        }
        else if (InterpreterNeeded && ID !== '' && Lang == "Other" && InterpreterData.OtherLang != "" && InterpreterType != '' && Name != "" && Representative != "" && !InterpreterEmailRequired && isInterpreterEmailRequired == "No") {
            setIsStartButtonEnabled(true);
        }
        else if (!InterpreterNeeded && (Reason.trim() !== "")) {
            setIsStartButtonEnabled(true);
            console.log(Reason)
        } else {
            setIsStartButtonEnabled(false);
        }
    };
    const checkIsFollowUpStartButtonEnabled = (data) => {
        const { providerName, diagnosis, dischargeDate } = data;
        if (providerName !== '' && diagnosis != '' && dischargeDate != "" ) {
            setIsFollowStartButtonEnabled(true);
        }
        else {
            setIsFollowStartButtonEnabled(false);
        }
    };

    const handelInterpreterLangClick = () => {
        let show = showccLLang
        if (!show) {
            setFilteredLanguages(languagesList)
            setShowCclLang(true)
            return
        }
        setShowCclLang(false)
    }

    return (
        <div id="overlay2">
            <div id="pin" className='py-4'>
                {(props.showInterpretion && props.registrationFormsList.length > 0) && <div className="col-12 d-flex justify-content-left m-0 p-0" onClick={() => props.setShowInterpretion(false)}>
                    <FontAwesomeIcon className="cursor-pointer" icon={faArrowLeft} />
                </div>}
                {!props.showInterpretion && !props.showBhFollowUp && <div className="col-12 d-flex justify-content-end m-0 p-0" onClick={() => props.setShowConfirmationScreen(false)}>
                    <FontAwesomeIcon className="cursor-pointer" icon={faXmark} />
                </div>}
                {props.showBhFollowUp && <div className="col-12 d-flex justify-content-end m-0 p-0" onClick={() => props.setShowConfirmationScreen(false)}>
                    <FontAwesomeIcon className="cursor-pointer" icon={faArrowLeft} />
                </div>}
                {(!props.showInterpretion && !props.showBhFollowUp) && <div className="row">
                    <div className="col-12">
                        <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                            <div className="col-12 d-flex justify-content-left m-0 p-1">
                                <div className="text-field w-100">
                                    <label className="lockScreenDetails">Would you like to send a copy of these registration forms:</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex registrationFormList">
                        <ul>
                            {props.registrationFormsList.map((form) => {
                                return (
                                    <li>
                                        {form.instrumentLabel}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {isEmailAvailable ?
                        <div className="col-12 d-flex justify-content-center">
                            <div className='row'>
                                <div className='col-12'>
                                    The copy of the forms will be sent on this email : {email}
                                </div>
                                <div className='col-12'>
                                    <span className='addNewEmailText' onClick={() => { setShowEmailField(true); setIsEmailAvailable(false) }}>click here</span>&nbsp;to change email address.
                                </div>
                            </div>

                        </div>
                        :
                        <div className="col-12 d-flex registrationFormList">
                            {showEmailField ?
                                <>
                                    <div class="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                        <div class="col-10 d-flex justify-content-left m-0 p-1">
                                            <div class="text-field w-100">
                                                <label class="lockScreenDetails">Please enter a valid email address</label>
                                            </div>
                                        </div>
                                        <div class="col-10 d-flex justify-content-left m-0 p-1">
                                            <div class="text-field w-100">
                                                <input class="w-100 finInput" required type="email" value={PDFemail} autocomplete="off" id="pinInput" onChange={(e) => inputHandler(e)} />
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center m-0 p-1">
                                            <div className='row'>
                                                <div class="col-6 d-flex justify-content-end">
                                                    <button className={"searchButton"} disabled={!isPDFEmailValid} onClick={() => onSaveEmail()}>Save</button>
                                                </div>
                                                <div class="col-6 d-flex justify-content-left">
                                                    <button className={"cancelButton"} onClick={() => { setShowEmailField(false); setIsEmailAvailable(true); setPDFEmail(""); }}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : <><div className='addNewEmailText' onClick={() => setShowEmailField(true)}>click here</div>&nbsp;to add a new email address.</>}
                        </div>}
                    <div className="col-12">
                        {!showEmailField &&
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                {props.isSuccess
                                    ?
                                    <div className="col-12 d-flex justify-content-center mt-3 p-1">
                                        <div className={"emailConfirmationScreenButton"} onClick={() => props.setShowConfirmationScreen(false)}>Okay</div>
                                    </div>
                                    :
                                    <>
                                        <div className="col-6 d-flex justify-content-end mt-3 p-1">
                                            <button className={"searchButton"} disabled={!isEmailValid} onClick={() => { props.confirmEmail(email, true) }}>Yes</button>
                                        </div>
                                        <div className="col-6 d-flex justify-content-start mt-3 p-1">
                                            <button className={"cancelButton"} onClick={() => { props.confirmEmail(email, false) }} >No</button>
                                        </div>
                                    </>}
                            </div>}
                    </div>
                </div>}
                {(props.showInterpretion && !props.showBhFollowUp) && <div className='ms-4'>
                    <div className="row mt-4">
                        <div className="col-12 ">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">

                                <div className="col-12 d-flex justify-content-left m-0 p-0">
                                    <div className="text-field d-flex w-100">
                                        <input
                                            type="checkbox"
                                            checked={!InterpreterData.InterpreterNeeded}
                                            id="InterpreterNeeded"
                                            style={{ height: "25px", width: "25px" }}
                                            onChange={(e) => handleInterpreterChange(e)}
                                        />
                                        <br />
                                        <div
                                            className="loggedInuser"
                                            id="notifylogedinEmail"
                                        >
                                            &nbsp; Interpreter Not Needed
                                        </div>
                                    </div>
                                </div>

                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center">
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {InterpreterData.InterpreterNeeded && <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-1 d-flex justify-content-left m-0 p-0 pb-2 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 p-0 ms-4">
                                    <label style={{ fontWeight: "normal" }}>
                                        Interpreter Language Used<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center" />
                            </div>
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-left m-0 p-0 pb-0 position-relative ms-4">
                                    <input
                                        className="w-100 finInput lookupInput arrow-input"
                                        autoComplete="off"
                                        id="Lang"
                                        name='Lang'
                                        placeholder='Enter interpreter language'
                                        value={InterpreterData.InterpreterNeeded ? InterpreterData.Lang : ""}
                                        onChange={(e) => handleInterpreterChange(e)}
                                        onClick={handelInterpreterLangClick}
                                        disabled={!InterpreterData.InterpreterNeeded}
                                    />
                                    {showccLLang && filteredLanguages.length > 0 && (
                                        <ul
                                            className="list-group hover-suggestion position-absolute w-100 overflow-y-auto"
                                            style={{
                                                top: "100%",
                                                zIndex: 110,
                                                backgroundColor: "#ffffff",
                                                borderRadius: "10px",
                                                maxHeight: "230px",
                                                border: "solid 0.5px #c7c7c7",
                                            }}
                                        >
                                            {filteredLanguages.map((lang) => (
                                                <li
                                                    className="list-group-item"
                                                    key={lang}
                                                    onClick={() => { setInterpreterData({ ...InterpreterData, Lang: lang }); setFilteredLanguages([]) }}
                                                    style={{
                                                        fontWeight: "500",
                                                        cursor: "pointer",
                                                        color: "black",
                                                        border: "none",
                                                        textAlign: "left",
                                                        paddingLeft: "10px",
                                                    }}
                                                >
                                                    {lang}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {(InterpreterData.Lang == "Other" && InterpreterData.InterpreterNeeded) && <div className="row mt-1">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-left m-0 p-0 pb-0 position-relative ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        autoComplete="off"
                                        id="OtherLang"
                                        name='Lang'
                                        placeholder='Enter interpreter language'
                                        value={InterpreterData.InterpreterNeeded ? InterpreterData.OtherLang : ""}
                                        onChange={(e) => handleInterpreterChange(e)}

                                        disabled={!InterpreterData.InterpreterNeeded}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {InterpreterData.InterpreterNeeded && <div className="row pt-2">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Interpreter ID<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name='ID'
                                        autoComplete="off"
                                        id="ID"
                                        value={InterpreterData.InterpreterNeeded ? InterpreterData.ID : ""}
                                        onChange={(e) => handleInterpreterChange(e)}
                                        disabled={!InterpreterData.InterpreterNeeded}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {InterpreterData.InterpreterNeeded && <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Interpreter Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name='Name'
                                        autoComplete="off"
                                        id="Name"
                                        value={InterpreterData.InterpreterNeeded ? InterpreterData.Name : ""}
                                        onChange={(e) => handleInterpreterChange(e)}
                                        disabled={!InterpreterData.InterpreterNeeded}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {InterpreterData.InterpreterNeeded && <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Patient/Representative Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name='Representative'
                                        autoComplete="off"
                                        id="Representative"
                                        value={InterpreterData.InterpreterNeeded ? InterpreterData.Representative : ""}
                                        onChange={(e) => handleInterpreterChange(e)}
                                        disabled={!InterpreterData.InterpreterNeeded}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {InterpreterData.InterpreterNeeded && <div className="row ">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label>
                                        Interpreter Type<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <select className="finInput lookupInput" id="InterpreterType" disabled={!InterpreterData.InterpreterNeeded} onChange={e => handleInterpreterChange(e)} value={InterpreterData.InterpreterType}>
                                        <option value="">Select Interpreter Type</option>
                                        <option value="In Person">In person</option>
                                        <option value="Video">Video</option>
                                        <option value="Call">Call</option>
                                    </select>
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {InterpreterData.InterpreterNeeded && (
                        <div className="row">
                            <div className="col-12">
                                <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                    <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                    <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0">
                                        <label style={{ fontWeight: "normal", textAlign: "left" }}>
                                            Do you want to send the form to interpreter?<span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                    <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                        <select className="finInput lookupInput" id="isInterpreterEmailRequired" disabled={!InterpreterData.InterpreterNeeded} onChange={e => handleInterpreterChange(e)} value={InterpreterData.isInterpreterEmailRequired}>
                                            <option value="">Please Select an Option</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    {/* <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4 IntEmailRequiredOptions">
                                            <input
                                                type="radio"
                                                name="InterpreterEmailRequired"
                                                id="yesEmailRequired"
                                                value="yes"
                                                disabled={!InterpreterData.InterpreterNeeded}
                                                onChange={() => handleInterpreterChange({ target: { id: 'InterpreterEmailRequired', value: true } })}
                                            />
                                            <label htmlFor='yesEmailRequired'>
                                            Yes
                                            </label>
                                            <div className='ms-1'>
                                            <input
                                                type="radio"
                                                name="InterpreterEmailRequired"
                                                id="noEmailRequired"
                                                value="no"
                                                disabled={!InterpreterData.InterpreterNeeded}
                                                onChange={() => handleInterpreterChange({ target: { id: 'InterpreterEmailRequired', value: false } })}
                                            />
                                        <label htmlFor='noEmailRequired'>
                                            No
                                        </label>
                                        </div>
                                    </div> */}
                                    <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {(InterpreterData.InterpreterNeeded && InterpreterData.InterpreterEmailRequired) && (
                        <div className="row">
                            <div className="col-12">
                                <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                    <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                    <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0">
                                        <label style={{ fontWeight: "normal" }}>
                                            Interpreter Email<span style={{ color: "red" }}>*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                    <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                        <input
                                            className="w-100 finInput lookupInput"
                                            name="InterpreterEmail"
                                            autoComplete="off"
                                            id="InterpreterEmail"
                                            value={InterpreterData.InterpreterEmail || ""}
                                            onChange={(e) => handleInterpreterChange(e)}
                                            disabled={!InterpreterData.InterpreterEmailRequired}
                                        />
                                    </div>
                                    <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!InterpreterData.InterpreterNeeded && !props.showBhFollowUp && <div className="row pt-2">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Comment<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <textarea
                                        type=""
                                        className=" finInput lookupInput"
                                        name='Reason'
                                        placeholder="Interpreter is not needed because..."
                                        autoComplete="off"
                                        id="Reason"
                                        style={{ height: "100px" }}
                                        required
                                        value={!InterpreterData.InterpreterNeeded ? InterpreterData.Reason : ""}
                                        onChange={(e) => handleInterpreterChange(e)}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>}
                    {!props.showBhFollowUp && <div class="col-12 d-flex justify-content-center m-0 p-1 mt-2">
                        <div className='row'>
                            <div class="col-6 d-flex justify-content-end">
                                <button className={"searchButton"} disabled={!isStartButtonEnabled} onClick={() => { props.startSurveyForms(InterpreterData) }}>Start</button>
                            </div>
                            <div class="col-6 d-flex justify-content-left">
                                <button className={"cancelButton"} onClick={() => { props.setShowConfirmationScreen(false); props.setShowInterpretion(false) }}>Cancel</button>
                            </div>
                        </div>
                    </div>}
                </div>}
                {props.showBhFollowUp && <div className='ms-4'>
                    {/* <div className="row pt-2">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Patient Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name='patientName'
                                        autoComplete="off"
                                        id="patientName"
                                        onChange={(e) => handleSearchItemChange(e)}
                                        value={sendFollowUpEmailData.patientName}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter MRN<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name='mrn'
                                        autoComplete="off"
                                        id="mrn"
                                        onChange={(e) => handleSearchItemChange(e)}
                                        value={sendFollowUpEmailData.mrn}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row col-12 ms-2">
                        <div className="col-6">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-12 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Patient DOB<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-12 d-flex justify-content-left m-0 p-0 ">
                                    <DatePicker
                                        value={sendFollowUpEmailData.patientDOB}
                                        className="custom-datepicker"
                                        id="patientDOB"
                                        inputClass='elementDate'
                                        format="MM/DD/YYYY"
                                        onChange={(date) => handleSearchItemChange({ target: { id: "patientDOB", value: date.format("MM/DD/YYYY") } })}
                                        placeholder='mm/dd/yyyy'
                                        maxDate={new DateObject()}
                                        style={{ maxWidth: "100%" }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-12 d-flex justify-content-left m-0 ms-4 p-0 ">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Discharge Date<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">

                                <div className="col-12 d-flex justify-content-left m-0 p-0 ">
                                    <DatePicker
                                        value={sendFollowUpEmailData.dischargeDate}
                                        className="custom-datepicker"
                                        id="dischargeDate"
                                        inputClass='elementDate'
                                        format="MM/DD/YYYY"
                                        onChange={(date) => handleSearchItemChange({ target: { id: "dischargeDate", value: date.format("MM/DD/YYYY") } })}
                                        placeholder='mm/dd/yyyy'
                                        maxDate={new DateObject()}
                                        style={{ maxWidth: "100%" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mt-2">
                        <div className='col-5 d-flex align-item-center ms-3 justify-content-center '>
                        <label style={{ fontWeight: "normal"}} className='justify-content-center '>
                            Enter Discharge Date<span style={{ color: "red" }}>*</span>
                        </label>
                        </div>
                        <div className="col-6 d-flex justify-content-left m-0 p-0">
                            <DatePicker
                                value={sendFollowUpEmailData.dischargeDate}
                                className="custom-datepicker"
                                id="dischargeDate"
                                inputClass='elementDate'
                                format="MM/DD/YYYY"
                                onChange={(date) => handleSearchItemChange({ target: { id: "dischargeDate", value: date.format("MM/DD/YYYY") } })}
                                placeholder='mm/dd/yyyy'
                                maxDate={new DateObject()}
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                        <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Rendering Provider Billed Name<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name="providerName"
                                        autoComplete="off"
                                        id="providerName"
                                        value={sendFollowUpEmailData.providerName}
                                        onChange={(e) => handleSearchItemChange(e)}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Primary Diagnosis Description<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <textarea
                                        className="w-100 finInput lookupInput"
                                        name="diagnosis"
                                        autoComplete="off"
                                        id="diagnosis"
                                        style={{ maxHeight: "100px" }}
                                        value={sendFollowUpEmailData.diagnosis}
                                        onChange={(e) => handleSearchItemChange(e)}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Patient Email<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name="patientEmail"
                                        autoComplete="off"
                                        id="patientEmail"
                                        value={sendFollowUpEmailData.patientEmail}
                                        onChange={(e) => handleSearchItemChange(e)}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div> */}
                    {/*<div className="row">
                        <div className="col-12">
                            <div className="row m-0 w-100 p-0 d-flex justify-content-center">
                                <div className="col-0 d-flex justify-content-left m-0 p-0 align-items-center" />
                                <div className="col-11 d-flex justify-content-left m-0 ms-4 p-0">
                                    <label style={{ fontWeight: "normal" }}>
                                        Enter Provider Email<span style={{ color: "red" }}>*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row m-0 w-100 p-0 pb-3 d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-left m-0 p-0 ms-4">
                                    <input
                                        className="w-100 finInput lookupInput"
                                        name="providerEmail"
                                        autoComplete="off"
                                        id="providerEmail"
                                        value={sendFollowUpEmailData.providerEmail}
                                        onChange={(e) => handleSearchItemChange(e)}
                                    />
                                </div>
                                <div className="col-1 d-flex justify-content-center m-0 p-0 align-items-center"></div>
                            </div>
                        </div>
                    </div>*/}
                    <div class="col-12 d-flex justify-content-center m-0 p-1 mt-2">
                        <div className='row'>
                            <div class="col-6 d-flex justify-content-end">
                                <button className={"searchButton"} disabled={!isFollowStartButtonEnabled} onClick={() => { props.startFollowUpSurveyForms(sendFollowUpEmailData) }}>Start</button>
                            </div>
                            <div class="col-6 d-flex justify-content-left">
                                <button className={"cancelButton"} onClick={() => { props.setShowConfirmationScreen(false); props.setShowBhFollowUp(false) }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default PatientEmailConfirmation;