import React, { useEffect, useState } from 'react'
import HistoryList from './historylist';
import getSurveyHistory from '../Api/getSurveyHistory';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../AppContextProvider';
import secrets from '../../env.json';

export const InstrumentList = (props) => {
    const location = useLocation();
    const { patientData, List, surveyData, selectionType } = location.state;
    const { FormData, getStatus, instrument } = props;
    const [history, setHistory] = useState(false);
    const [loader, setLoader] = useState(false);
    const [surveyHistory, setSurveyHistory] = useState();
    const [statusData, setStatusData] = useState({
        historyStatus: false,
        status: <div></div>
    });
    const { accessToken } = useAuth();
    useEffect(() => {
        if(props.isRegistrationForm && instrument.isAutoSelect){
            var newForm = {
                pid: props.data.pid ? props.data.pid : props.instrument.pid,
                instrumentName: instrument.instrumentName,
                instrumentLabel: instrument.instrumentLabel,
                surveyType: instrument.surveyType,
                surveyId: instrument.surveyId,
                isRegistrationForm: instrument.isRegistrationForm,
                status: 1,
                isInterpretationRequired:instrument.isInterpretationRequired,
                isBHFollowUp:instrument.isBHFollowUp
            };
            if (!(selectionType !== "encounter" && props.instrument.surveyType !== "")) {
                props.selectForms(newForm);
                props.setRegFormListUpdated(newForm);
            }
        }

    }, [selectionType,props.instrument]);
    const getSurveyData = (pid, instrumentName, surveyId) => {
        var requestBody = {
            "recordId": props.recordId,
            "surveyId": surveyId
        }
        setLoader(true);
        getSurveyHistory(requestBody, accessToken)
            .then((response) => {
                setLoader(false);
                setSurveyHistory(response);
            })
            .catch((err) => {
                setLoader(false);

            });
    }
    const checkboxToggle = () => {
        var pid = props.data.pid ? props.data.pid : props.instrument.pid;
        var isCheckedLocal = props.isSurveySelected(pid, props.instrument.instrumentName);
        props.alterChosenForms(pid, instrument.instrumentName, instrument.instrumentLabel, !isCheckedLocal, instrument.surveyType, instrument.surveyId, instrument.encounterTypes, instrument.isRegistrationForm,instrument.isInterpretationRequired,instrument.isBHFollowUp);

    }

    useEffect(() => {
        setStatusData(getStatus(props.FormData, props.instrument, props.data.pid))
        setHistory(false);
        setSurveyHistory()
    }, [FormData])
    return (
        <div className="instruments" style={{ fontSize: "18px", height: props.isRegistrationForm ? "120px" : "" }}>
            <div className="row">
                <div className={ "col-10"}>
                    {props.instrument.instrumentLabel}
                    {(selectionType !== "encounter" && props.instrument.surveyType !== "") &&
                        <p className="" style={{ marginLeft: props.isRegistrationForm ? "0px" : "5px", fontWeight: "lighter", paddingTop: "0.7rem", fontSize: "1rem" }}>
                            <div className='apEmail'>{"(Please select an encounter to send this survey)"}</div>
                        </p>
                    }
                </div>
                <div className="col-2">
                    <label
                        className="custom-checkbox-label"
                        onClick={() => {
                            checkboxToggle();
                        }}
                    >
                        {!props.isSurveySelected(props.data.pid?props.data.pid:props.instrument.pid, props.instrument.instrumentName)? (
                            <span>&#9744;</span>
                        ) : (
                            <span>&#9745;</span>
                        )}
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-7">
                    {props.isRegistrationForm ? <></> : <>{statusData.status}</>}
                </div>
                {statusData.historyStatus && <div className="col-5 d-flex justify-content-end historyText">
                    <div
                        onClick={() => {
                            setHistory(!history);
                            getSurveyData(props.data.pid, props.instrument.instrumentName, props.instrument.surveyId);
                        }}
                    >
                        {" "}
                        View History{" "}
                    </div>{" "}
                    <div className="pt-1">{history ? "▼" : "▲"}</div>
                </div>}
            </div>
            {history ? (
                <div className="row HistoryTable ">
                    <div
                        className="row m-0 d-none d-lg-flex justify-content-space-between px-1 py-3 sticky-top "
                        style={{ backgroundColor: "#E9ECEF", zIndex: "0" }}
                    >
                        <div
                            className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Status</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Sent Date</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">
                                Date Modified
                            </span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Email Used</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">
                                Phone Number Used
                            </span>
                        </div>
                        <div
                            className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">Sent By</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left pl-4"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">
                                Additional Party
                            </span>
                        </div>
                    </div>
                    <HistoryList HistoryData={surveyHistory} key={props.instrument} />
                </div>
            ) : (
                <></>
            )}
        </div>
    )
}