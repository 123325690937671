// Navigation.js
import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../images/Chlalogo-01.png";
import { useHistory } from "react-router-dom";
import {
  matchPath,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../AppContextProvider";
import { useOktaAuth } from '@okta/okta-react';
import EnvConfirmationDialog from "./EnvConfirmationDialog";
import secrets from '../../env.json';

const Navigation = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [activeNavItem, setActiveNavItem] = useState("finlookup"); // Set the default active item
  const [lastSelection, setLastSelection] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    userInfo,
    isAuthenticated,
    isAuthorized,
    userAccessStatus
  } = useAuth();

  const handleNavItemClick = (itemId, Route) => {
    setActiveNavItem(itemId);
    history.replace(`/${Route}`);
  };

  useEffect(() => {
    if (matchPath("/patients/*", location.pathname)) {
      setActiveNavItem("finlookup");
    } else if (matchPath("/visits/*", location.pathname)) {
      setActiveNavItem("Visitlookup");
    }
  //   else if (matchPath("/bhfollow/*", location.pathname)) {
  //   setActiveNavItem("bhfollowup");
  // }
    else if (matchPath("/admin/*", location.pathname)) {
      setActiveNavItem("admin");
    }
  });
  const [selectedOption, setSelectedOption] = useState(localStorage.getItem('cernerENV'));

  const handleChange = (event) => {
    if(event.target.value !== selectedOption){
      setShowConfirmation(true);
      setLastSelection(selectedOption);
      setSelectedOption(event.target.value);
    }
  };

  const onConfirmation = (type) => {
    if(type){
      setShowConfirmation(false);
      localStorage.setItem('cernerENV', selectedOption);
    }
    else{
      setShowConfirmation(false);
      setSelectedOption(lastSelection);
    }
  }

  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    await oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin+'/logout'
    });
  };

  return (
    <>
      {showConfirmation &&
       <>
        <EnvConfirmationDialog onConfirmation={onConfirmation}/>
       </>}
      {isAuthenticated && (
        <>
          <nav className="navbar navbar-expand-lg fixed-top d-flex" style={{flexDirection:"column", paddingTop:"0px"}} id="nav">
          {`${secrets.REACT_APP_CERNER_ENV}` !== "prod" && (
             <div style={{width:"100%" ,padding:"10px"}} id="navEnviroment">
              <div
                  className="d-flex"
                  style={{ justifyContent: "end" }}
                >
                  <div className="domainText">Cerner Domain :</div>
                  <div>
                   <select
                     className="envDrop"
                     id="dropdown"
                     value={selectedOption}
                     onChange={handleChange}
                   >
                     <option value="build">BUILD</option>
                     <option value="cert">CERT</option>
                     <option value="prod">PROD</option>
                   </select>
                 </div>
               </div>
             </div>
          )}
            <div className="container-fluid">
              <a className="navbar-brand" href={window.location.origin}>
                <img className="logo" id="logo" src={Logo} />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {isAuthorized && (
                    <>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeNavItem === "finlookup" ? "active" : ""
                          }`}
                          id="finlookup"
                          onClick={() =>
                            handleNavItemClick("finlookup", "patients")
                          }
                          //   href="/"
                        >
                          Patient Lookup
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeNavItem === "Visitlookup" ? "active" : ""
                          }`}
                          id="Visitlookup"
                          onClick={() =>
                            handleNavItemClick("Visitlookup", "visits")
                          }
                          //   href="/visits"
                        >
                          Visits Lookup
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          className={`nav-link ${
                            activeNavItem === "bhfollowup" ? "active" : ""
                          }`}
                          id="bhfollowup"
                          onClick={() =>
                            handleNavItemClick("bhfollowup", "bhfollow")
                          }
                          //   href="/visits"
                        >
                         BH FollowUp
                        </a>
                      </li> */}
                      {userAccessStatus === "admin_access" && 
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeNavItem === "admin" ? "active" : ""
                            }`}
                            id="AdminPage"
                            onClick={() =>
                              handleNavItemClick("admin", "admin")
                            }
                            //   href="/visits"
                          >
                            Admin Dashboard
                          </a>
                        </li>
                      }
                    </>
                  )}
                </ul>
                <form className="d-flex">
                  <div className="navTab my-2 my-sm navTab" id="userName">
                    {userInfo && <span>Welcome &nbsp; {userInfo.email}</span>}
                  </div>
                  <a href={secrets.REACT_APP_REDCAP_REDIRECT_URL} style={{textDecoration:"none", color: "white"}}>
                  <div className="navTab my-2 my-sm navTab" id="redcap">
                    <img src="https://app-appdev-redcap-dev-001.ase-eapps-prod-001.p.azurewebsites.net/redcap_v13.8.3/Resources/images/favicon.ico"></img>Redcap
                  </div>
                  </a>
                  <div className="navTab my-2 my-sm navTab" id="logout" onClick={logout}>
                    Logout
                  </div>
                </form>
              </div>
            </div>
          </nav>
        </>
      )}
    </>
  );
};

export default Navigation;
